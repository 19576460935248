<template>
  <div>
    <b-row class="content-header mb-2">
      <b-col class="col-md-6">
        <h2 class="content-header-title float-left pr-1 mb-0">Farm</h2>
      </b-col>
    </b-row>

    <b-card no-body class="cardBody">
      <div class="d-flex justify-content-end">
        <b-button variant="primary" :to="{
          name: 'apps-farm-add',
        }" class="mr-2 mt-3 custom-btn">
          <feather-icon icon="PlusIcon" class="mr-25" />
          Add Farm
        </b-button>
      </div>
      <div v-if="farm_list.length > 0">
        <b-table :items="farm_list.data" id="farmTable" responsive :fields="fields"
          empty-text="No matching records found" class="position-relative">
          <template #cell(index)="data">
            <div class="ml-1">
              {{ farm_list.from + data.index }}
            </div>
          </template>
          <template #cell(status)="data">
            <div v-if="data.value === 'Active'" class="activeClass">Active</div>
            <div v-else class="inactiveClass">Inactive</div>
          </template>

          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon icon="EditIcon" size="16" v-b-modal.edit-farm @click="() => getFarmById(data.item.id)" />
              <b-tooltip title="Edit" :target="`invoice-row-${data.item.id}-preview-icon`" />
              <feather-icon @click="() => setFarmId(data.item.id)" icon="EyeIcon" size="16" class="mx-1"
                v-b-modal.modal-farm />
              <b-tooltip title="View" :target="`invoice-row-${data.item.id}-preview-icon`" />
            </div>
          </template>
        </b-table>
        <div v-bind:class="[farm_list.length === 1 ? ' m-2' : ' row m-5']">
          <div v-for="item in farm_list" v-bind:key="item.id">
            <div v-if="farm_list.length === 1">
              <b-col md="12" class="single-farm-view p-0 card-view">
                <div class="top-section p-2 d-flex justify-content-between"
                  v-bind:class="[status === active ? 'activeClass' : 'inactiveClass']">
                  <div class="single-item">
                    <b-img :src="require('@/assets/images/svg/hatchery/single-hatchery.svg')" class="svg-img"
                      height="30px" width="30px" />

                    <div class="card-text">
                      <h5 class="ml-2">{{ item.name }}</h5>
                      <span class="ml-2">Created date:</span>
                      <span> {{ setDateFormat(item.created_at) }}</span>
                    </div>
                  </div>
                  <b-dropdown variant="link" no-caret right class="chart-dropdown ml-1" toggle-class="p-0">
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="24" class="text-white cursor-pointer" />
                    </template>
                    <b-dropdown-item>
                      <router-link class="text-secondary ml-1" :to="{
                        name: 'apps-farm-edit',
                        params: { farmId: `${item.id}` },
                      }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit Farm</span>
                      </router-link>
                    </b-dropdown-item>
                    <div class="" @click="farmView(item.id)">
                      <b-dropdown-item>
                        <div class="ml-1">
                          <feather-icon icon="EyeIcon" />
                          <span class="align-middle ml-50">Shed </span>
                        </div>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
                <!-- <b-card class="hatchery-single-view"> -->
                <div class="single-list-view p-2">
                  <b-row>
                    <b-col md="3">
                      <b-row>
                        <b-col md="2">
                          <b-img :src="require('@/assets/images/svg/hatchery/setter.svg')" class="svg-img" />
                        </b-col>
                        <b-col>
                          <div class="itemText">Shed</div>
                          <div class="itemHeading">
                            {{ item.shedCount }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="3">
                      <b-row>
                        <b-col md="2">
                          <b-img :src="require('@/assets/images/svg/hatchery/created.svg')" class="svg-img" />
                        </b-col>
                        <b-col>
                          <div class="itemText">Created By</div>
                          <div class="itemHeading">{{ item.createdBy }}</div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </div>
            <div v-else>
              <b-col md="4">
                <div class="card-block card-view">
                  <div class="top-section" v-bind:class="[item.status === 'Active' ? 'activeClass' : 'inactiveClass']">
                    <div class="cardText">
                      <h5>{{ item.name }}</h5>
                      <p>{{ item.address }}</p>
                      <p class="card-text font-small-3">
                        {{ setDateFormat(item.created_at) }}
                      </p>
                      <b-img :src="require('@/assets/images/svg/hatchery/card-top.svg')" class="svg-img" />
                    </div>
                  </div>
                  <div class="py-1">
                    <div class="list-item">
                      <b-row>
                        <b-col class="col-md-5 item-text"> Shed </b-col>
                        <b-col class="item-right-text">{{ item.shedCount }} </b-col>
                      </b-row>
                    </div>
                    <div class="list-item">
                      <b-row>
                        <b-col class="col-md-5 item-text"> Created by </b-col>
                        <b-col class="item-right-text">{{ item.createdBy }}</b-col>
                      </b-row>
                    </div>
                    <!-- <div class="mx-1 mt-1">
											<div class="view-section">
												<b-link class="view-assign" @click="farmView(item.id)">
													Add Shed</b-link
												>
											</div>
										</div> -->
                    <div class="row mb-1">
                      <div class="col-6">
                        <div class="mx-1 mt-1">
                          <div class="view-section" v-b-tooltip.hover.left="{ variant: 'info' }" title="Edit Farm !">
                            <b-link class="view-text" :to="{
                              name: 'apps-farm-edit',
                              params: { farmId: `${item.id}` },
                            }">
                              <b-img :src="require('@/assets/images/svg/hatchery/edit.svg')" class="svg-img-add"
                                height="15px" width="15px" />
                            </b-link>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mx-1 mt-1">
                          <div class="view-section"
                          v-b-tooltip.hover.right="{ variant: 'info' }" title="Shed !">
                            <b-link class="view-assign" @click="farmView(item.id)">
                              <b-img :src="require('@/assets/images/svg/hatchery/view.svg')" class="svg-img-add"
                                height="15px" width="15px" />
                            </b-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex flex-column align-items-center py-5">
        <b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
        <h4 class="mt-2 Secondary">There is No list of Farm</h4>
      </div>
    </b-card>
  </div>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";

import axiosIns from "@/libs/axios";

export default {
  components: {
    vSelect,
  },
  created() {
    this.companyId = this.$route.params.companyId;
    this.getUserFarm();
  },
  data() {
    return {
      selected: null,
      farm_id: null,
      fields: [
        {
          shedCount: null,
          createdBy: null,
          farm_name: null,
          id: null,
          created_at: null,
        },
      ],
      farm_list: [],
      farm: null,
      farm_machine: null,
      length: null,
      farm_name: null,
      companyId: null,
      farmId: null,
    };
  },
  methods: {
    getUserFarm() {
      let companyId = this.companyId;
      let url = "";
      if (companyId) {
        url = `web/company/${companyId}/getUserFarmList`;
      } else {
        url = `web/getUserFarmList`;
      }
      axiosIns
        .get(url)
        .then((response) => {
          this.farm_list = response.data.user_farm_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    farmView(farmId) {
      this.$router.push({ path: `/apps/farm/${farmId}/view` });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/hatchery.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/_common.scss";

.card-view {
  cursor: pointer;
}

.view-assign {
  font-size: 13px;
}

.svg-img-add {
  margin-top: 0.75rem;
  width: 25px;
  height: 25px;
}
</style>